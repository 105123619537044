import React, { useState, MouseEvent, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Typography, Toolbar, Box, AppBar, Drawer, List, ListItem, Divider, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import DevicesIcon from '@mui/icons-material/Devices';
import ChargingStationIcon from '@mui/icons-material/EvStation';

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      ((event as KeyboardEvent).key === 'Tab') || 
      ((event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <Button
            component={Link}
            to="/"
            startIcon={<MapIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Map
          </Button>
        </ListItem>
        <ListItem>
          <Button
            component={Link}
            to="/devices"
            startIcon={<DevicesIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Devices
          </Button>
        </ListItem>
        <ListItem>
          <Button
            component={Link}
            to="/charging"
            startIcon={<ChargingStationIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Charging
          </Button>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'dimgrey', paddingTop: '8px' }}>
        <Toolbar>
          {/* IconButton for Drawer Toggle */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'block', sm: 'none' }, marginRight: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Links for larger screens */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', marginRight: 'auto' }}>
            <Typography variant="h6" component="div" sx={{ color: 'white', marginRight: '20px' }}>
              <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Map</Link>
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: 'white', marginRight: '20px' }}>
              <Link to="/devices" style={{ color: 'white', textDecoration: 'none' }}>Devices</Link>
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: 'white' }}>
              <Link to="/charging" style={{ color: 'white', textDecoration: 'none' }}>Charging</Link>
            </Typography>
          </Box>

          {/* Drawer Component */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {drawerList()}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}