import { useEffect, useRef, useState } from 'react';

interface MessageObject<T> {
  channel: string;
  payload: T;
}

const useWebSocket = <T,>(url: string, channels: string[]) => {
  const [messages, setMessages] = useState<MessageObject<T>[]>([]);
  const socketRef = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    socketRef.current = new WebSocket(url);

    const handleOpen = () => {
      console.log('Connected to WebSocket server');
      setIsConnected(true);

      // Subscribe to channels
      channels.forEach(channel => {
        const subscriptionMessage = JSON.stringify({ action: 'subscribe', channel });
        console.log(`Subscribing to channel: ${channel}`);
        socketRef.current?.send(subscriptionMessage);
      });
    };

    const handleMessage = (event: MessageEvent) => {
      console.log('Message from server:', event.data);
      const parsedData: MessageObject<T> = JSON.parse(event.data);
      setMessages(prevMessages => [...prevMessages, parsedData]);
    };



    const handleClose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
    };

    socketRef.current.addEventListener('open', handleOpen);
    socketRef.current.addEventListener('message', handleMessage);
    socketRef.current.addEventListener('close', handleClose);

    return () => {
      if (socketRef.current) {
        socketRef.current.removeEventListener('open', handleOpen);
        socketRef.current.removeEventListener('message', handleMessage);
        socketRef.current.removeEventListener('close', handleClose);
        socketRef.current.close();
      }
    };
  }, [url, channels]);

  const sendMessage = (message: string) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    } else {
      console.warn('WebSocket is not open. Unable to send message:', message);
    }
  };

  return { messages, sendMessage, isConnected };
};

export default useWebSocket;


