import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MapGrid from './components/grids/golfMapGrid';
import DevicesGrid from './components/grids/devicesGrid';
import ChargingGrid from './components/grids/chargingGrid';
import Navbar from './components/navigation/navbar';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<MapGrid />} />
        <Route path="/:deviceID?"element={<MapGrid />} />
        <Route path="/devices" element={<DevicesGrid />} />
        <Route path="/charging" element={<ChargingGrid />} />
      </Routes>
    </Router>
  );
}

export default App;
