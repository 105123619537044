import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { allDevLocationAtom } from '../atoms/allDevLocationAtom';
import GolfMap from '../charts-graphs/golfMap';
import useWebSocket from '../wsHook';
import DevLocation from '../defs/devLocation-def';
import { useParams } from 'react-router-dom';
import { deviceLocationAtom } from '../atoms/deviceLocationAtom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface DevLocationMessage {
  channel: string;
  payload: DevLocation;
}

export default function GolfMapHandler() {
  const [ ,setDevLocations] = useAtom(allDevLocationAtom);
  const [ ,setDeviceLocation] = useAtom(deviceLocationAtom);
  const { messages } = useWebSocket<DevLocation>('https://odp.golf/websocket', ['dataTable_update']);
  const { deviceID } = useParams<{ deviceID?: string }>();

  const { isFetching, isError, error } = useQuery({
    queryKey: ['fallbackLocation'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<DevLocation[]>('https://odp.golf/api/fallback-location');
      const data = response.data;
      UpdateDevLocations(data);
      console.log("all fallback locations: ", data);
      return data;
    }
  });

  function UpdateDevLocations(data: DevLocation[]) {
    const updatedDevLocations = data?.map(loc => ({
      ...loc
    }));
    setDevLocations(updatedDevLocations);
  }

  useEffect(() => {
    messages.forEach((messageObj) => {
      const { channel, payload } = messageObj;
      if (channel === 'dataTable_update') {
        try {
          // const convertedData = ConvertPayload(data);
          const convertedData = payload;

          if (convertedData && convertedData.lat != null && convertedData.lng != null) {
            const newDevLocation = {
              deviceID: convertedData.deviceID,
              lat: convertedData.lat,
              lng: convertedData.lng,
              area: null,
              timestamp:''
            };

            setDevLocations((prevDevLocations) => {
              const updatedLocations = prevDevLocations.filter(
                (loc) => loc.deviceID !== newDevLocation.deviceID
              );
              return [...updatedLocations, newDevLocation];
            });

            if (deviceID && deviceID === newDevLocation.deviceID) {
              setDeviceLocation([newDevLocation]);
              console.log('Updated device locations:', newDevLocation);
            }
          } else {
            console.warn('Received data with invalid or incomplete location info:', convertedData);
          }
        } catch (err) {
          console.error('Error parsing message:', err);
        }
      }
    });
  }, [messages, setDevLocations, deviceID, setDeviceLocation]);

    const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <div>
      {renderLoadingOrError()}
      <GolfMap />
    </div>
  );
}


